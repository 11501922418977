import styled from "styled-components";

export const HeaderWrapper = styled.div`
  .navbar {
    background-color: black;

    .show {
      a {
        color: black;
      }

      .active {
        a {
          color: white;
        }
      }
    }

    a {
      color: white;
    }

    .offcanvas {
      width: 60%;
    }

    .navbar-toggler {
      position: absolute;
      right: 10px;
      background: radial-gradient(
          circle closest-corner at 42% -100%,
          #ff1313,
          rgba(237, 155, 169, 0)
        ),
        linear-gradient(103deg, #a576fc 16%, #ffa61d 88%);
    }

    .nav-item:hover {
      background-color: #1d1d1d;
      border-radius: 22px;
    }

    .active {
      background-color: #007eee;
      border-radius: 22px;
    }
  }
`;
