import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, ProgressStepper, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
} from "./styles";

export default function StolpersteineIOS() {
  const PR2 = require("../../../../assets/images/pr-2.jpeg");
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const R1 = require("../../../../assets/images/projects/robot-simulation/r1.png");
  const R2 = require("../../../../assets/images/projects/robot-simulation/r2.png");
  const R3 = require("../../../../assets/images/projects/robot-simulation/r3.png");
  const R4 = require("../../../../assets/images/projects/robot-simulation/r4.png");
  const R5 = require("../../../../assets/images/projects/robot-simulation/r5.png");
  const Grid1 = require("../../../../assets/images/projects/robot-simulation/grid1.svg");
  const Grid2 = require("../../../../assets/images/projects/robot-simulation/grid2.png");
  const Grid3 = require("../../../../assets/images/projects/robot-simulation/grid3.png");

  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState([
    {
      id: "1",
      size: "1400-933",
      src: R1,
      thumb: R1,
      subHtml: ``,
    },

    {
      id: "2",
      size: "1400-933",
      src: R2,
      thumb: R2,
      subHtml: ``,
    },
    {
      id: "3",
      size: "1400-932",
      src: R3,
      thumb: R3,
      subHtml: ``,
    },

    {
      id: "4",
      size: "1400-932",
      src: R4,
      thumb: R4,
      subHtml: ``,
    },
    {
      id: "5",
      size: "1400-932",
      src: R5,
      thumb: R5,
      subHtml: ``,
    },
  ]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img className="img-responsive w-100 rounded" src={item.thumb} />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  QC Assist - iOS and Hololens
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  QR Assist - Interactive 3D and AR Product Exploration QR
                  Assist is an interactive application that allows users to
                  explore and interact with a Conel's product in both 3D
                  interactive and AR scenarios. This application is designed to
                  provide users with a detailed understanding of the product,
                  its internal components, measurements, and installation
                  process.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid1} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>3D Interactive Environment: </b>
                  Users can tap on different parts of the model in exploded view
                  to explore its internal components. This feature helps users
                  understand the functionality and workings of the product.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Augmented Reality (AR): </b> Users can activate the AR
                  feature to place the 3D model in the real world using the
                  device's camera. They can move around to explore the model
                  from different angles and distances.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Detailed Measurements:</b> Users can check out the
                  measurements of the model using the app. This feature is
                  useful for users who need to know the product's exact
                  dimensions for installation purposes.
                </p>
                <p className="pb-2">
                  <b>Step-by-Step Installation Guide:</b> The app provides a
                  step-by-step guide on how to install the model, including a
                  brief description of the installation process. This feature
                  helps users save time and avoid any installation-related
                  issues.
                </p>
              </div>
            </div>

            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0 styled-text1 text-warning">
                        QR Assist is an innovative and user-friendly application
                        that provides users with a comprehensive understanding
                        of Conel's products. The app is designed to help users
                        make informed decisions when purchasing a product and to
                        facilitate the installation process. Experience the
                        power of interactive 3D and AR product exploration with
                        QR Assist.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <GalleryContainer>
              <LightGallery
                plugins={[lgVideo, lgZoom]}
                elementClassNames="gallery-custom-wrapper row flex-column flex-sm-row"
                onInit={onInit}
                data-lg-size={2}
              >
                {getItems()}
              </LightGallery>
            </GalleryContainer>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
