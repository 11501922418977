import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, ProgressStepper, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function RobotSimulation() {
  const PR2 = require("../../../../assets/images/pr-2.jpeg");
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const R1 = require("../../../../assets/images/projects/robot-simulation/r1.png");
  const R2 = require("../../../../assets/images/projects/robot-simulation/r2.png");
  const R3 = require("../../../../assets/images/projects/robot-simulation/r3.png");
  const R4 = require("../../../../assets/images/projects/robot-simulation/r4.png");
  const R5 = require("../../../../assets/images/projects/robot-simulation/r5.png");
  const Grid1 = require("../../../../assets/images/projects/robot-simulation/grid1.svg");
  const Grid2 = require("../../../../assets/images/projects/robot-simulation/grid2.png");
  const Grid3 = require("../../../../assets/images/projects/robot-simulation/grid3.png");

  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState([
    {
      id: "1",
      size: "1400-933",
      src: R1,
      thumb: R1,
      subHtml: ``,
    },

    {
      id: "2",
      size: "1400-933",
      src: R2,
      thumb: R2,
      subHtml: ``,
    },
    {
      id: "3",
      size: "1400-932",
      src: R3,
      thumb: R3,
      subHtml: ``,
    },

    {
      id: "4",
      size: "1400-932",
      src: R4,
      thumb: R4,
      subHtml: ``,
    },
    {
      id: "5",
      size: "1400-932",
      src: R5,
      thumb: R5,
      subHtml: ``,
    },
  ]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img className="img-responsive w-100 rounded" src={item.thumb} />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Robot simulation in VR & AR
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  VR Robot Control - Interactive Learning with 3D Printed
                  Controller Welcome to the VR Robot Control project, an
                  immersive learning experience designed to teach users how to
                  work with a robot using a 3D printed controller and a
                  Bluetooth module within a Virtual Reality (VR) environment.
                  Built specifically for the HTC Vive platform, this project
                  provides a realistic and engaging space for users to interact
                  with a robot manually.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid1} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Immersive VR Environment </b>
                </p>
                <p>
                  Step into a virtual world where you can learn and practice
                  interacting with a robot. The VR environment offers a safe and
                  controlled space for users to visualize and understand the
                  robot's movements and actions. 3D
                </p>
                <p className="pb-2 mt-4">
                  <b> Printed Controller</b>
                </p>
                <p>
                  Experience a tactile and realistic interaction with the robot
                  using a 3D printed controller. The controller features buttons
                  and joysticks that mimic the controls of the robot, providing
                  a hands-on experience.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Bluetooth Communication</b>
                </p>
                <p>
                  {" "}
                  The Bluetooth module enables wireless communication between
                  the 3D printed controller and the robot, ensuring seamless
                  control and responsiveness.
                </p>
                <p className="pb-2 mt-4">
                  <b> Three Stages of Learning</b>
                </p>
                <p>
                  The application consists of three stages: Drilling, Pick and
                  Place, and Welding. Each stage is designed to test the user's
                  ability to work with the robot in different scenarios,
                  providing a comprehensive learning experience.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Drilling Stage</b>
                </p>
                <p>
                  Users are challenged to drill a hole in a given object using
                  the robot, testing their precision and control.
                </p>
                <p className="pb-2">
                  <b>Pick and Place Stage</b>
                </p>
                <p>
                  Users utilize the robot's arm to pick up an object and place
                  it in a designated location, honing their manipulation and
                  coordination skills.
                </p>
                <p>
                  <b>Welding Stage</b>
                </p>
                <p>
                  Users are tasked with welding two objects together using the
                  robot, developing their welding techniques and understanding
                  of robotic welding processes.
                </p>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        The VR Robot Control project aims to provide an
                        interactive and engaging learning experience for users
                        interested in working with robots. By combining a 3D
                        printed controller, Bluetooth communication, and a VR
                        environment, users can gain hands-on experience, develop
                        their skills in robot control, and explore the exciting
                        world of robotics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <GalleryContainer>
              <LightGallery
                plugins={[lgVideo, lgZoom]}
                elementClassNames="gallery-custom-wrapper row flex-column flex-sm-row"
                onInit={onInit}
                data-lg-size={2}
              >
                {getItems()}
              </LightGallery>
            </GalleryContainer>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
