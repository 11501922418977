import React from "react";

import { PrimaryHeading } from "../../../styles";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function BremerhavenGuideIOS() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/bremerhaven-guide/t1.png");
  const T2 = require("../../../../assets/videos/bremerhaven-guide/t2.png");
  const T3 = require("../../../../assets/videos/bremerhaven-guide/t3.png");
  const T4 = require("../../../../assets/videos/bremerhaven-guide/t4.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Unveiling the Treasures of Bremerhaven through Immersive
                  Exploration
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Embark on an extraordinary journey through the enchanting
                  seaside city of Bremerhaven with the BremerhavenGuide mobile
                  app. Designed to guide visitors to the most captivating sights
                  and historical landmarks, this app is your gateway to an
                  immersive and interactive exploration of Bremerhaven's rich
                  heritage.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremerhaven-guide/1_BHV.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b> Tailored Tours</b>
                </p>
                <p>
                  {" "}
                  BremerhavenGuide offers a diverse range of curated tours,
                  including enthralling historical tours and captivating fishery
                  harbor tours. Immerse yourself in the city's culture, history,
                  and maritime essence through thoughtfully designed tours or
                  create your own unique adventure.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <p className="pb-2">
                  <b> Interactive Digital City Map</b>
                </p>
                <p>
                  {" "}
                  Navigate Bremerhaven with ease using the interactive digital
                  city map provided by BremerhavenGuide. The map not only
                  displays your current location but also showcases the selected
                  tour route and the precise position of individual
                  destinations. Stay oriented, plan your route effortlessly, and
                  access real-time directions to make the most of your
                  exploration.
                </p>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremerhaven-guide/2_BHV.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T3}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremerhaven-guide/3_BHV.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Engaging Multimedia Experiences </b>
                </p>
                <p>
                  As you arrive at each designated point of interest,
                  BremerhavenGuide transforms into a captivating multimedia hub.
                  Delve into a wealth of captivating information presented
                  through engaging videos, immersive audio stories, vivid
                  pictures, and insightful texts. Experience Bremerhaven's
                  vibrant past and present come to life, as you uncover
                  fascinating details about each attraction.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Augmented Reality Enhancements</b>
                </p>
                <p>
                  {" "}
                  Prepare for a truly immersive and interactive encounter at
                  select points of interest. Look out for the "Augmented
                  Reality" (AR) note on specific POI pages. By harnessing the
                  power of AR technology, BremerhavenGuide offers specially
                  crafted user experiences, including interactive visualizations
                  and exciting panoramic views. Immerse yourself in an
                  extraordinary blend of reality and digital enhancements,
                  elevating your exploration to new heights.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T4}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremerhaven-guide/4_BHV.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        BremerhavenGuide is designed to be accessible and
                        interactive, ensuring that everyone can enjoy the
                        wonders of Bremerhaven. The app can be downloaded free
                        of charge from the App Store and Google Playstore,
                        following activation by the provider. It has received
                        acclaim and recognition, being nominated under the
                        "Future Bremerhaven" category at the prestigious
                        Tourismuspreis Bremen und Bremerhaven 2022. This
                        recognition highlights the app's commitment to
                        sustainability, creativity, and innovation in the realm
                        of Bremerhaven tourism.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
