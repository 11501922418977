import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function RyllMessse() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const T1 = require("../../../../assets/videos/ryllmesse/t1.png");
  const R1 = require("../../../../assets/videos/ryllmesse/HoloLens-Multiuser-L.jpg");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Ryll Messe (Multi-user) - Hololens
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Welcome to Ryll Messe, the cutting-edge mixed reality
                  application specifically developed for Hololens devices.
                  Designed for Ryll Company's expo presentations, Ryll Messe
                  introduces a new paradigm of collaborative 3D model
                  interaction. Ryll Messe is a multi-user application that
                  allows individuals to join and simultaneously view a shared 3D
                  model within the same physical location. Leveraging the power
                  of mixed reality technology, users can engage in collaborative
                  interactions, creating an immersive and interactive experience
                  like never before.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/ryllmesse/1_Rylle+Messe.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Simultaneous Viewing </b>
                </p>
                <p>
                  Multiple users can join the same session and view the 3D model
                  together, fostering collaboration and enabling real-time
                  discussions.
                </p>
                <p className="pb-2 pt-3">
                  <b>Innovative Design:</b>
                </p>
                <p>
                  {" "}
                  With its forward-thinking design, Ryll Messe merges
                  cutting-edge technology seamlessly with user-friendly
                  interfaces, ensuring a user-centric and intuitive experience.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Collaborative Interaction:</b>
                </p>
                <p>
                  Users can interact with the 3D model, manipulating, exploring,
                  and examining its various aspects collectively, promoting
                  teamwork and knowledge sharing.
                </p>
                <p className="pt-3">
                  <b> Varying Scenarios or Scenes</b>
                </p>
                <p>
                  Ryll Messe offers three different use cases, each presenting
                  unique scenarios or scenes, enabling users to experience a
                  range of diverse 3D models.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={R1} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6"></div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        Furthermore, Ryll Messe empowers users to engage in
                        dynamic and immersive presentations, leveraging
                        interactive elements embedded within the 3D model to
                        captivate audiences and convey ideas effectively. With
                        its innovative design and advanced mixed reality
                        technology, Ryll Messe revolutionizes the way people
                        interact with 3D models in a collaborative setting. By
                        providing a shared and interactive environment, Ryll
                        Messe enhances collaboration, knowledge exchange, and
                        communication, ultimately transforming expo
                        presentations into engaging and impactful experiences.
                        Experience the future of collaborative 3D model
                        interaction with Ryll Messe.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
