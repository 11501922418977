import styled from "styled-components";
import { flexCenter } from "../../styles";
import { fontSize_BASE, fontSize_XXL } from "../../customFonts";

export const StyledGrid = styled.div`
  padding: 18px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
`;

export const GridBox = styled.div`
  padding: 50px 36px;
  ${flexCenter};
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 20px;
  }

  h5,
  h2 {
    ${fontSize_BASE};
    line-height: 24px;
    text-align: center;
    font-weight: 600;
  }

  h2 {
    color: #000;
    text-align: left;
    ${fontSize_XXL};
    padding-bottom: 14px;
  }

  p {
    text-align: left;
    padding: 12px 0;
  }

  b {
    ${fontSize_BASE};
    font-weight: 900;
  }

  img {
    width: 100% !important;
    border-radius: 12px;
    height: 650px;
    object-fit: cover;

    @media (max-width: 480px) {
      padding: 0;
    }
  }

  h5 {
    font-weight: bold;
  }

  .viewdetails {
    a {
      color: white;
      font-size: 14px;
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 0;
      background-color: #353535;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 26px 0;
      text-decoration: none;
      left: 0;
    }

    .disabled {
      cursor: no-drop;
      background: #ccc;
      opacity: 0.5;
    }
  }
`;
