import styled from "styled-components";
import { fontSize_SM, fontSize_XXXL } from "../customFonts";

export const Banner = styled.div`
  .experience-container {
    padding: 20px 0;

    .left {
      border-right: solid 1px rgb(238 238 238 / 17%);
    }

    .right {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    h3,
    p {
      color: white;
    }
  }

  .introduction-text {
    font-size: 24px;

    @media (max-width: 480px) {
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    padding-bottom: 30px;
  }
`;

export const StyledPersonIconContainer = styled.div`
  position: relative;

  .lottie {
    position: absolute;
    bottom: 0;
    left: 0;

    .lottie-icon {
      width: 180px;

      @media (max-width: 480px) {
        width: 120px;
      }
    }
  }

  img {
    width: 100%;
    border-bottom: solid 1px #232323;
  }
`;
