import React, { FunctionComponent } from "react";
import { CopyrightBar } from "./styles";

const Footer: FunctionComponent = () => {
  return (
    <>
      <CopyrightBar>
        <p>Bharath Vadde Copyright © 2020. All rights reserved.</p>
      </CopyrightBar>
    </>
  );
};

export default Footer;
