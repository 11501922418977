import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, ProgressStepper, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
} from "./styles";

export default function ARConnect() {
  const PR2 = require("../../../../assets/images/pr-2.jpeg");
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const R1 = require("../../../../assets/images/projects/robot-simulation/r1.png");
  const R2 = require("../../../../assets/images/projects/robot-simulation/r2.png");
  const R3 = require("../../../../assets/images/projects/robot-simulation/r3.png");
  const R4 = require("../../../../assets/images/projects/robot-simulation/r4.png");
  const R5 = require("../../../../assets/images/projects/robot-simulation/r5.png");
  const Grid1 = require("../../../../assets/images/projects/robot-simulation/grid1.svg");
  const Grid2 = require("../../../../assets/images/projects/robot-simulation/grid2.png");
  const Grid3 = require("../../../../assets/images/projects/robot-simulation/grid3.png");

  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState([
    {
      id: "1",
      size: "1400-933",
      src: R1,
      thumb: R1,
      subHtml: ``,
    },

    {
      id: "2",
      size: "1400-933",
      src: R2,
      thumb: R2,
      subHtml: ``,
    },
    {
      id: "3",
      size: "1400-932",
      src: R3,
      thumb: R3,
      subHtml: ``,
    },

    {
      id: "4",
      size: "1400-932",
      src: R4,
      thumb: R4,
      subHtml: ``,
    },
    {
      id: "5",
      size: "1400-932",
      src: R5,
      thumb: R5,
      subHtml: ``,
    },
  ]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img className="img-responsive w-100 rounded" src={item.thumb} />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  AR Connect
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  AR Connect - Empowering Remote Collaboration with Augmented
                  Reality Welcome to AR Connect, a revolutionary application
                  that harnesses the power of augmented reality to enable
                  seamless remote collaboration. With AR Connect, geographical
                  constraints no longer limit brand agents, product experts,
                  customers, and field technicians from visually guiding and
                  collaborating with each other.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid1} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Virtual Rooms and Avatars:</b>AR Connect allows users to
                  create virtual rooms and invite others to join. Once everyone
                  has joined, users can place content in their respective
                  locations, creating a shared augmented reality space. Users
                  can see each other as avatars, promoting a sense of presence
                  and enhancing collaboration. Communication between users is
                  facilitated through voice communication, fostering real-time
                  interactions,discussions and presentations.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Product Demonstrations and Remote Assistance:</b> AR
                  Connect serves as an exceptional tool for product
                  demonstrations and remote assistance. Product experts can
                  guide customers or field technicians through complex processes
                  and tasks by visually overlaying instructions and information
                  in the shared augmented reality environment. This immersive
                  experience provides a unique and interactive way for users to
                  collaborate, troubleshoot, and provide real-time assistance,
                  regardless of their physical location.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Enhanced Communication and Collaboration:</b> AR Connect
                  showcases the immense potential of augmented reality to
                  enhance communication and collaboration across various fields.
                  By seamlessly integrating virtual and physical spaces, AR
                  Connect breaks down geographical barriers and enables experts
                  and users to work together as if they were in the same
                  location. This not only improves efficiency but also enhances
                  knowledge transfer and fosters a stronger sense of teamwork.
                </p>
              </div>
            </div>

            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0 styled-text1 text-warning">
                        AR Connect is at the forefront of leveraging augmented
                        reality to transform remote collaboration. By bridging
                        the gap between physical distance and real-time
                        interaction, AR Connect empowers users to collaborate
                        effectively, demonstrate products remotely, and provide
                        unparalleled assistance. Experience the future of remote
                        collaboration with AR Connect.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <GalleryContainer>
              <LightGallery
                plugins={[lgVideo, lgZoom]}
                elementClassNames="gallery-custom-wrapper row flex-column flex-sm-row"
                onInit={onInit}
                data-lg-size={2}
              >
                {getItems()}
              </LightGallery>
            </GalleryContainer>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
