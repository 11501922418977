import styled from "styled-components/macro";

export const Button = styled.button`
  cursor: pointer;
  border-radius: 36px;
  font-size: 14px;
  margin: 0 5px;
  color: white;
  background-color: transparent;
  border: solid 1px #eee;
  font-weight: 400;
  padding: 5px 18px;
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #007eee;
    color: white;
  }
`;
