import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function BremenGoIOS() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/bremen-go/t1.png");
  const T2 = require("../../../../assets/videos/bremen-go/t2.png");
  const T3 = require("../../../../assets/videos/bremen-go/t3.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Unleash the Wonders of Bremen's City Center with the Ultimate
                  Mobile Guide
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Embark on an unforgettable journey through the heart of Bremen
                  city with the BremenGo mobile app. Designed to guide and
                  captivate visitors, BremenGo is your key to unlocking the
                  city's most fascinating sights and historical landmarks.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremen-go/1_Bremen+go.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Discover Bremen's Treasures</b>
                </p>
                <p>
                  {" "}
                  Experience the magic of Bremen's city center like never before
                  with BremenGo. Immerse yourself in the rich history and
                  cultural heritage of this vibrant city. From iconic landmarks
                  to hidden gems, the app unveils the true essence of Bremen's
                  captivating charm.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <p className="pb-2">
                  <b> Tours</b>
                </p>
                <p>
                  Currently, BremenGo offers an exclusive Old Town Tour that
                  takes you on a captivating adventure through the city's
                  historic center. Stay tuned for future updates as additional
                  tours are planned, expanding your exploration options.
                </p>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremen-go/2_Bremen+go.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T3}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremen-go/3_BremenGo.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b> Interactive City Map and Navigation </b>
                </p>
                <p>
                  Navigate the city streets with ease using the interactive city
                  map provided by BremenGo. The map displays your current
                  location, the designated tour route, and the precise positions
                  of the Points of Interest (POI). Whether you prefer to follow
                  a suggested itinerary or create your own, BremenGo ensures a
                  seamless and personalized exploration of Bremen's city center.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Engaging Multimedia Content</b>
                </p>
                <p>
                  Immerse yourself in a captivating multimedia experience at
                  each POI with BremenGo. Dive into a wealth of informative and
                  entertaining resources, including videos, audio stories,
                  stunning images, and engaging text. Uncover the stories behind
                  Bremen's landmarks, learn about its historical significance,
                  and immerse yourself in its vibrant culture.
                </p>

                <p className="pb-2 mt-4">
                  <b> Augmented Reality Experiences</b>
                </p>
                <p>
                  Take your journey to the next level with BremenGo's Augmented
                  Reality (AR) features. Activate AR mode at select POIs can let
                  you experience captivating visualizations, fun interactions
                  and informative content digitally. Enjoy immersive views of
                  Bremen's landmarks. Let AR transport you to a world where
                  history and modern technology converge.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T3}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/bremen-go/3_BremenGo.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        BremenGo is designed to be inclusive and interactive,
                        catering to all visitors. The app is available for free
                        download from the App Store and Google Playstore,
                        granting you effortless access to its wealth of
                        information and features. Join thousands of fellow
                        explorers and embark on a journey to discover the
                        captivating history and allure of Bremen's city center
                        with BremenGo as your trusted companion.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
