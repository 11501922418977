import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function ArgoHytosAR() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/argo-hytos/t1.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  ARGOHYTOS AR
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  ARGO HYTOS AR - Augmented Reality for Enhanced Product
                  Visualization ARGO HYTOS AR is an interactive 3D and Augmented
                  Reality (AR) application developed for the ARGO-HYTOS Group
                  that provides a comprehensive depiction of four key ARGO-HYTOS
                  Group products. This cutting-edge app combines the power of
                  interactive 3D visualization and AR technology to deliver an
                  immersive and informative experience.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/argo-hytos/1_ArgoHytosAR.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Product Overview: </b>
                </p>
                <p>
                  Explore detailed product descriptions, functionalities, and
                  specifications, gaining a comprehensive understanding of each
                  ARGO-HYTOS Group product's capabilities and benefits.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-12">
                <p className="pb-2">
                  <b>Interactive 3D and AR: </b>
                </p>
                <p>
                  Immerse yourself in interactive 3D models that bring the
                  products to life, allowing you to examine them from every
                  angle. Experience the products in Augmented Reality, placing
                  virtual representations of the products in your real-world
                  environment for an enhanced visual perspective.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-12">
                <p className="pb-2">
                  <b>Labels and Simulations:</b>
                </p>
                <p>
                  Visualize labels and annotations that provide valuable
                  insights into the various components and functionalities of
                  the products. Engage with interactive simulations to grasp
                  their real-world application.
                </p>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        With ARGO HYTOS AR, users can access detailed
                        information about ARGO-HYTOS Group products and
                        experience them in both 3D and Augmented Reality. This
                        interactive and engaging application showcases the
                        functionalities of the products, features & benefits
                        empowering users to make informed decisions and better
                        understand their products. ARGO HYTOS AR provides a
                        unique opportunity to delve into the world of ARGO-HYTOS
                        Group products in an intuitive and immersive manner.
                        Experience the power of augmented reality and
                        interactive 3D visualization with ARGO HYTOS AR.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
