import React from "react";
import { PrimaryHeading } from "../../../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";
import { ImageRotation } from "../styles";

export default function BIBA() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const Grid1 = require("../../../../assets/images/projects/robot-simulation/grid1.svg");
  const Grid2 = require("../../../../assets/images/projects/robot-simulation/grid2.png");
  const Grid3 = require("../../../../assets/images/projects/robot-simulation/grid3.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  BIBA Qualify AR: Augmented Reality Redefining Education
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  The technical professions in the aviation industry are
                  becoming more and more complex, which means that new ways of
                  imparting knowledge in the context of training and further
                  education are required. A precise evaluation of the work
                  results is needed for what has been learned to be correctly
                  implemented and applied to new learning situations. This
                  should ensure a high level of execution quality in aircraft
                  construction. However, during the training, it is difficult to
                  deal with all trainees individually, which means that the work
                  results can only be assessed selectively. To counteract this,
                  augmented reality (AR) technologies are to be used in training
                  and further education. With the help of AR, additional
                  information can be shown and hidden, which is intended to
                  support the user with a learning success or quality test.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid1} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>BIBA WFE </b>
                </p>
                <p>
                  {" "}
                  The Workflow Editor (WFE) is a comprehensive desktop
                  application designed to empower teachers in creating dynamic
                  and interactive projects. With an intuitive interface,
                  teachers can construct projects with multiple steps by
                  choosing from a range of preset options. These steps can be
                  customized to align with specific project requirements.
                  Teachers can also enhance the learning experience by
                  incorporating 3D references. The flexibility of the WFE allows
                  teachers to easily rearrange steps and seamlessly switch
                  between different project stages. Once the project is
                  finalized, teachers can publish it for student access.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Qualify AR Hololens</b>
                </p>
                <p>
                  {" "}
                  In this the students embark on an immersive educational
                  journey. By wearing the Hololens 2 device, opening the app and
                  logging in, students gain access to their project list
                  generated from the WFE. Each project consists of a series of
                  steps, accompanied by clear descriptions and requirements. To
                  ensure accurate alignment between the digital 3D models and
                  physical objects, students use QR code scanning. For certain
                  steps, an inspection process is initiated where students
                  capture images of their work, which are then sent to a server
                  for analysis. Prompt feedback is provided based on the
                  inspection results, allowing students to monitor their
                  progress and make necessary adjustments. They can seamlessly
                  navigate between steps, revisit previous stages, or move
                  forward as they learn and grow.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Lernplatform for Enhanced Teacher-Student Collaboration</b>
                </p>
                <p>
                  {" "}
                  The Lernplatform is a powerful desktop application that
                  facilitates teacher-student collaboration and reviews. Through
                  this application, teachers can evaluate student work by
                  leveraging data collected from the Hololens application.
                  Students, too, can access the Lernplatform to review the
                  feedback provided by their teachers. This comprehensive
                  platform serves as a hub for communication, progress tracking,
                  and assessment, enabling teachers and students to engage in a
                  dynamic and interactive learning environment.
                </p>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        The BIBA Qualify AR project incorporates a centralized
                        database, ensuring real-time data synchronization across
                        all applications. This seamless integration offers
                        students and teachers immediate access to updated
                        information, enhancing collaboration and learning
                        outcomes. BIBA Qualify AR is at the forefront of
                        educational innovation, harnessing the power of
                        augmented reality to create engaging and immersive
                        learning experiences. By empowering teachers with
                        advanced project creation tools, providing students with
                        captivating AR interactions, and facilitating
                        comprehensive review and assessment, this project paves
                        the way for the future of education. Please note that
                        BIBA Qualify AR is currently a research project and the
                        applications are not publicly available for download.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
