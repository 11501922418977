import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, ProgressStepper, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function PilkingtonAR() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const T1 = require("../../../../assets/videos/pilkington/t1.png");
  const T2 = require("../../../../assets/videos/pilkington/t2.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Pilikington AR
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  PilkingtonAR - Augmented Reality for Immersive Exploration
                  Welcome to PilkingtonAR, an innovative mobile application that
                  harnesses the power of augmented reality to provide immersive
                  experiences and valuable information. With two distinct
                  options to choose from, Museum and Fassade, PilkingtonAR opens
                  up new possibilities for digital exploration and design.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/pilkington/1_Pilkington_Mirrorpane.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Museum Option</b>
                </p>
                <p>
                  Step into a virtual world Explore the German Mining Museum in
                  Bochum through augmented reality.
                </p>
                <p>
                  {" "}
                  Place the pump installation equipped with Pilkington
                  Mirropane™ Chrome Spy spy mirror in your own environment.
                </p>
                <p>
                  {" "}
                  Access technical data and detailed information about the
                  project.
                </p>
                <p>
                  {" "}
                  Seamlessly scale the application to real size and walk through
                  the exhibit.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b> Fassade Option </b>
                </p>
                <p>Design glass facades with ease using augmented reality.</p>
                <p>
                  <b> </b> Access information on different glass products and
                  technical specifications.
                </p>
                <p>
                  <b> </b> Place a glass facade in your environment and explore
                  various glass options.
                </p>
                <p>
                  <b> </b> Observe how the glasses react to different parts of
                  the day, simulating the sun's position, and discover the
                  functionality of self-cleaning glass.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/pilkington/2_Pilkington_Fassade.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        PilkingtonAR revolutionizes the way users interact with
                        cultural exhibits and explore architectural design
                        possibilities. By seamlessly blending virtual elements
                        with the real world, the app offers a truly immersive
                        experience. Whether you're an art enthusiast, an
                        architect, or someone fascinated by innovative glass
                        solutions, PilkingtonAR provides an engaging and
                        informative platform for augmented reality exploration.
                        Step into a world where digital and physical
                        environments seamlessly merge with PilkingtonAR.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
