import styled, { css } from "styled-components";
import { sideBar, arrowDimentions } from "../variables";
import { fontSize_BASE, fontSize_XXL, fontSize_XXXL } from "./customFonts";
import { typography } from "./Theme/Typography";

export interface Selected {
  isOpen?: boolean;
  isTextWhite?: string;
}

export const flexCenter = css`
  align-items: center;
  justify-content: center;
`;

export const sectionPadding = css`
  padding: 100px 0;
`;

export const alignedCenter = css`
  align-items: center;
`;

export const displayFlex = css`
  display: flex;
`;

export const cursorPointer = css`
  cursor: pointer;
`;

export const Padding0y50x = css`
  padding: 0px 50px;
`;

export const fontThin = css`
  font-weight: 200;
`;

export const fontRegular = css`
  font-weight: 400;
`;

export const fontMedium = css`
  font-weight: 500;
`;

export const fontBold = css`
  font-weight: 700;
`;

export const FontLight = styled.b`
  font-weight: 400;
  color: ${(props) => props.theme.primary.fontLight};
`;

export const FlexCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
`;

export const DisplayCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
  flex: 1;
`;

export const rowSpaceBetween = styled.div`
  ${displayFlex};
  flex-flow: column;
`;

export const ApplicationContainer = styled.div`
  width: 100%;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;

  .piechart-wrapper {
    border-bottom: solid;
  }
`;

export const MainContainer = styled.div`
  height: 100%;

  @media (max-width: 480px) {
    height: auto !important;
  }
`;

export const AdView = styled.div`
  width: ${sideBar};
  background-color: #ccc;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  background-image: linear-gradient(to right, #7073c7, #3f51b5);
`;

export const ArrowView = styled.div<Selected>`
  position: fixed;
  right: ${(props) => (props.isOpen ? "200px" : "0")};
  bottom: 0;
  background-color: ${(props) => props.theme.primary.whiteColor};
  border: solid 2px ${(props) => props.theme.primary.color};
  border-radius: 100px;
  width: ${arrowDimentions};
  height: ${arrowDimentions};
  ${displayFlex};
  ${flexCenter};
  ${cursorPointer};
  z-index: 9999;
`;

export const HomeImageView = styled.div`
  ${displayFlex};
  ${flexCenter};

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
`;

export const TitleHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    width: 100% !important;
  }

  h5 {
    ${fontMedium};
    text-transform: capitalize;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
  }

  .title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
    color: ${(props) => props.theme.primary.THEME};
    font-style: italic;

    span {
      ${fontBold};
      font-style: normal;
      ${fontBold};
      letter-spacing: 1.2px;
      color: ${(props) => props.theme.primary.SECONDARY_THEME};
    }
  }
`;

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  @media (max-width: 767px) {
    position: relative;
  }
`;

export const SpecialFont = styled.b`
  color: ${(props): string => props.theme.primary.SECONDARY_THEME};
  font-size: 25px;
`;

export const Section = styled.div`
  ${sectionPadding};
`;

export const SectionHeading = styled.div<Selected>`
  ${fontSize_XXL};
  font-weight: 600;
  color: #000;
  padding: 0 0 10px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  b {
    padding-right: 10px;

    &::after {
      content: "";
      width: 80px;
      border-radius: 3px;
      display: block;
      margin-top: 12px;
      bottom: 0px;
      left: 0px;
      height: 5px;
      width: 53px;
      background-image: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
      background-size: 120% 2px;
    }
  }

  p {
    ${fontSize_BASE};
    color: ${(props: any) => props.theme.primary.THEME};
  }

  .tagline {
    text-align: center;
    width: 50%;

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;

export const Subscribe = styled.div`
  width: 30%;
  position: relative;

  p {
    text-align: center;
  }

  input {
    border-radius: 20px;
    height: 36px;
  }

  button {
    position: absolute;
    right: -4px;
    top: 37px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const BgHeading = styled.div`
  img {
    width: 100%;
    height: 720px;
    object-fit: cover;

    @media (max-width: 480px) {
      object-fit: contain;
      height: auto;
    }
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    line-height: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 26px;

    @media (max-width: 480px) {
      color: #000;
      position: relative;
      left: 0;
      top: auto;
      transform: translate(0%, 0%);
      display: flex;
      justify-content: center;
    }
  }
`;

export const PrimaryHeading = styled.h1`
  background-image: radial-gradient(
      circle closest-corner at 42% -100%,
      #ff1313,
      rgba(237, 155, 169, 0)
    ),
    linear-gradient(103deg, #a576fc 16%, #ffa61d 88%);

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;

  @media (max-width: 480px) {
    font-size: 20px !important;
    line-height: auto !important;
  }
`;

export const SecondaryHeading = styled(PrimaryHeading)`
  background-image: radial-gradient(
      circle closest-corner at 42% -100%,
      #blue,
      rgba(237, 155, 169, 0)
    ),
    linear-gradient(103deg, #9076fc 16%, #61cfeb 88%);
  font-size: ${typography.font.size.f36}px;
`;

export const CanvasContainer = styled.div`
  @media (max-width: 480px) {
    margin: 0 50px;
  }

  canvas {
    width: 80% !important;
    height: 80% !important;
    position: absolute;
    top: 30%;
    opacity: 0.4;
  }
`;

export const BackgroundImage = styled.div`
  background-position: bottom !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 700px;

  @media (max-width: 480px) {
    height: auto;
  }
`;

export const ScrollableAboutUs = styled.div`
  max-height: 80vh;
  overflow: auto;

  h4 {
    font-size: ${typography.font.size.f20}px;
  }

  p,
  b,
  li {
    font-size: ${typography.font.size.f14}px;
    margin-bottom: 0;
  }

  p {
    margin: 10px 0;
  }

  h4,
  p,
  b,
  li {
    color: white;
    padding: 0;
  }

  @media (max-width: 480px) {
    max-height: initial;
    overflow: initial;
    padding-bottom: 30px;
  }
`;

export const SkillSetContainer = styled.div`
  .grid {
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    transition: transform 0.3s ease;
    flex-direction: column;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

  p {
    color: black;
    text-align: center;
    font-weight: 600;
    font-style: italic;
  }
`;

export const ProgressStepper = styled.div`
  .resume {
    width: 100%;
    color: white;
    box-shadow: 10px 10px black;
    position: relative;
    display: flex;
  }

  .resume .func {
    box-sizing: border-box;
    float: left;
  }

  .resume .func > div {
    padding-bottom: 10px;
  }

  .resume .func > div:last-of-type {
    padding-bottom: 0;
  }

  .resume .func {
    width: 100%;
  }

  .resume .func:hover > div {
    transition-duration: 0.5s;
  }

  .resume .func:hover > div:hover h3 i {
    transform: scale(1.25);
  }

  .resume .func h3 {
    transition-duration: 0.3s;
    margin-top: 0;
  }

  .resume .func .work {
    float: left;
  }

  .resume .func .work ul {
    padding-left: 0;
  }

  .resume .func .work small {
    display: block;
    opacity: 0.7;
  }

  .resume .func .work ul li {
    position: relative;
    margin-left: 15px;
    padding-left: 25px;
    padding-bottom: 15px;
  }

  .resume .func .work ul li:hover::before {
    animation: circle 1.2s infinite;
  }

  .resume .func .work ul li:hover span {
    color: pink;
  }

  @keyframes circle {
    from {
      box-shadow: 0 0 0 0px yellow;
    }

    to {
      box-shadow: 0 0 0 6px rgba(255, 255, 255, 0);
    }
  }

  .resume .func .work ul li:first-of-type::before {
    width: 10px;
    height: 10px;
    left: 1px;
  }

  .resume .func .work ul li:last-of-type {
    padding-bottom: 3px;
  }

  .resume .func .work ul li:last-of-type::after {
    border-radius: 1.5px;
  }

  .resume .func .work ul li::before,
  .resume .func .work ul li::after {
    content: "";
    display: block;
    position: absolute;
  }

  .resume .func .work ul li::before {
    width: 12px;
    height: 12px;
    border: 3px solid white;
    background: orange;
    border-radius: 50%;
    left: 1px;
    z-index: 1;
    top: -1px;
  }

  .resume .func .work ul li::after {
    width: 3px;
    height: 100%;
    background: white;
    left: 5px;
    top: 0;
  }

  .resume .func .work ul li span {
    transition-duration: 0.3s;
  }

  .resume .func .work {
    width: 100%;
  }
`;
