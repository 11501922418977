import React from "react";
import { PrimaryHeading, Section } from "../styles";
import { GridBox } from "../UIComponents/FourGrid/styles";
import Lottie from "lottie-react";
import { ScrollableAboutUs } from "../styles";
import { ProgressStepper, SkillSetContainer } from "../styles";

export default function AboutUs() {
  const UnityImg = require("../../assets/images/unity.png");
  const CImg = require("../../assets/images/c.png");
  const ARcoreImg = require("../../assets/images/arcore.png");
  const BlenderImg = require("../../assets/images/blender.png");
  const GithubImg = require("../../assets/images/github.png");
  const JIRAImg = require("../../assets/images/jira.png");
  const MRImg = require("../../assets/images/mr.png");
  const WebARImg = require("../../assets/images/webar.png");

  const Img1 = require("../../assets/images/person2.png");

  const skillData = [
    {
      id: 1,
      src: UnityImg,
      title: "Unity",
    },
    {
      id: 2,
      src: CImg,
      title: "C#",
    },
    {
      id: 3,
      src: ARcoreImg,
      title: "AR Foundation",
    },
    {
      id: 4,
      src: BlenderImg,
      title: "Blender",
    },
    {
      id: 5,
      src: GithubImg,
      title: "Github",
    },
    {
      id: 6,
      src: JIRAImg,
      title: "JIRA",
    },
    {
      id: 7,
      src: MRImg,
      title: "Mixed Reality",
    },
    {
      id: 6,
      src: WebARImg,
      title: "WebAR",
    },
  ];

  return (
    <div className="bg-black h-100">
      <div className="container">
        <div className="m-0 h-100  d-flex align-items-start flex-column flex-sm-row">
          <div className="col-12 col-sm-4 ps-0 pe-0 pe-sm-5 pt-2 pt-sm-5 animate__animated animate__fadeInLeft">
            <img src={Img1} alt="" className="w-100 border-bottom" />
          </div>
          <ScrollableAboutUs className="col-12 col-sm-8 mt-5 pb-5">
            <div>
              <div className="m-0 row  animate__animated animate__fadeInUp">
                <PrimaryHeading className="display-1 pb-2 pb-sm-3 ps-0">
                  Biography
                </PrimaryHeading>
                <p className="text-white ps-0">
                  With a strong background in Unity, I developed a wide range of
                  mobile applications that seamlessly blend the virtual and
                  physical worlds. Leveraging the power of ARKit and ARCore,
                  they have created captivating experiences that allow users to
                  interact with virtual objects, explore virtual worlds, and
                  engage with digital content in ways never before possible.
                </p>
              </div>

              <div className="m-0 row pt-4 mt-3 animate__animated animate__fadeInUp">
                <PrimaryHeading className="display-1 pb-2 pb-sm-3 ps-0">
                  Education
                </PrimaryHeading>
                <div className="row">
                  <div className="col-12 col-sm-12 ps-0">
                    <ProgressStepper>
                      <div className="resume">
                        <div className="func">
                          <div className="work">
                            <ul>
                              <li>
                                <span>M.Sc Mechatronics</span>
                                <small>Hochschule Ravensburg-Weingarten</small>
                                <small>
                                  Weingarten, Baden Württemberg
                                </small>{" "}
                                <small>GPA: 2.1/5.0</small>
                                <small>02-2019</small>
                              </li>
                              <li>
                                <span>B.Tech Mechanical Engineering</span>
                                <small>RVR & JC College of engineering</small>
                                <small>
                                  Guntur, Andhra Pradesh, India.
                                </small>{" "}
                                <small>
                                  GPA: 8.96/10.0 (Pass with distinction)
                                </small>
                                <small>05-2016</small>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ProgressStepper>
                  </div>
                </div>
              </div>

              <div className="m-0 row pt-4 mt-3 animate__animated animate__fadeInUp">
                <PrimaryHeading className="display-1 pb-2 pb-sm-3 ps-0">
                  Experience
                </PrimaryHeading>

                <div className="row">
                  <div className="col-12 col-sm-12 ps-0">
                    <ProgressStepper>
                      <div className="resume">
                        <div className="func">
                          <div className="work">
                            <ul>
                              <li>
                                <span>
                                  Senior Augmented / Mixed reality developer
                                </span>
                                <small>Radiusmedia KG | Bremen, Bremen</small>
                                <small>07-2019 - Present</small>
                              </li>
                              <li>
                                <span>UReality | Deutschland, Bremen</span>
                                <small>University of America</small>
                                <small>11-2017 - 08-2018</small>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ProgressStepper>
                  </div>
                </div>
              </div>

              <SkillSetContainer className="m-0 row pt-4 mt-3 animate__animated animate__fadeInUp">
                <PrimaryHeading className="display-1 pb-2 pb-sm-3 ps-0">
                  Skills
                </PrimaryHeading>

                <div className="row">
                  {skillData.map((item: any) => {
                    return (
                      <div className="col-12 col-sm-2 grid">
                        <img src={item.src} />
                        <p>{item.title}</p>
                      </div>
                    );
                  })}
                </div>
              </SkillSetContainer>

              <div className="m-0 row pt-4 mt-3">
                <PrimaryHeading className="display-1 pb-2 pb-sm-3 ps-0">
                  Honors & Awards
                </PrimaryHeading>

                <div className="row">
                  <div className="col-12 ps-0">
                    <ul>
                      <li className="pb-3">
                        - Acknowledged by the esteemed Late Dr. APJ Abdul Kalam,
                        former President of India, for my contributions to
                        designing the ATV(All Terrain Vehicle) in 2015
                      </li>
                      <li>
                        - 'BremerhavenGuide' is nominated for the
                        Tourismuspreis(2022) under the Future Bremerhaven
                        category.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </ScrollableAboutUs>
        </div>
      </div>
    </div>
  );
}
