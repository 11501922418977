import React, { useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import { Link } from "react-router-dom";
import { CarouselContainer, SlideItem, Tabs, ProjectGrid } from "./styles";
import { GridBox } from "../../UIComponents/FourGrid/styles";
import { PrimaryHeading } from "../../styles";

export interface CarouselItem {
  id: number;
  src: string;
  name: string;
  linkTo: string;
  category: string;
  className?: string;
  width?: number;
  itemsToShow?: number;
  itemsToScroll?: number;
}

export default function Projects() {
  const carouselRef = useRef<any>(null as any);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedTab, setSelectedTab] = useState("All Projects");
  const PlaceholderImg = require("../../../assets/images/placeholder-300*600.svg");

  const RobotSimulationImg = require("../../../assets/images/projects/robot-simulation/main.svg");
  const GCAssistImg = require("../../../assets/videos/gc-assist/t1.png");
  const HoloworkAssistImg = require("../../../assets/videos/holowork-assist/t1.png");
  const ArgoHytosARImg = require("../../../assets/videos/argo-hytos/t1.png");
  const RyllmesseImg = require("../../../assets/videos/ryllmesse/t1.png");
  const PilkingtonARImg = require("../../../assets/videos/pilkington/t3.png");
  const SaackeScrubberImg = require("../../../assets/videos/saacke-scrubber/t1.png");
  const ConelWebImg = require("../../../assets/videos/conel-web/t1.png");
  const StolpersteinImg = require("../../../assets/videos/stolperstein-nrw/t3.png");
  const BremerhavenGuideImg = require("../../../assets/videos/bremerhaven-guide/t3.png");
  const BremenGoImg = require("../../../assets/videos/bremen-go/t3.png");

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 5, itemsToScroll: 1 }, // Adjusted itemsToShow and itemsToScroll
  ];

  const items: CarouselItem[] = [
    {
      id: 1,
      src: RobotSimulationImg,
      name: "Robot Simulation",
      linkTo: "/RobotSimulation",
      category: "All Projects", // Add category for each item
      className: "",
    },
    {
      id: 2,
      src: GCAssistImg,
      name: "GCAssist",
      linkTo: "/GCAssist",
      category: "AR Development",
    },
    {
      id: 3,
      src: HoloworkAssistImg,
      name: "HoloworkAssist",
      linkTo: "/HoloworkAssist",
      category: "VR Development",
    },
    {
      id: 4,
      src: ArgoHytosARImg,
      name: "ArgoHytosAR",
      linkTo: "/ArgoHytosAR",
      category: "Web AR",
    },
    {
      id: 5,
      src: RyllmesseImg,
      name: "RyllMesse",
      linkTo: "/RyllMesse",
      category: "3D Modelling",
    },
    {
      id: 6,
      src: PilkingtonARImg,
      name: "PilkingtonAR",
      linkTo: "/PilkingtonAR",
      category: "3D Modelling",
    },
    // {
    //   id: 7,
    //   src: PlaceholderImg,
    //   name: "Multiuser AR",
    //   linkTo: "/ARConnect",
    //   category: "AR Development",
    // },
    // {
    //   id: 8,
    //   src: PlaceholderImg,
    //   name: "Multiuser Hololens",
    //   linkTo: "/HoloConnect",
    //   category: "AR Development",
    // },

    {
      id: 9,
      src: SaackeScrubberImg,
      name: "Saacke-Scrubber",
      linkTo: "/Saacke-Scrubber",
      category: "AR Development",
    },
    {
      id: 10,
      src: ConelWebImg,
      name: "ConelWeb",
      linkTo: "/ConelWeb",
      category: "AR Development",
    },
    {
      id: 11,
      src: StolpersteinImg,
      name: "Stolpersteine Prototype",
      linkTo: "/Stolpersteine Prototype",
      category: "AR Development",
    },
    {
      id: 12,
      src: PlaceholderImg,
      name: "Stolpersteine - iOS(no content)",
      linkTo: "/StolpersteineIOS",
      category: "AR Development",
    },
    {
      id: 13,
      src: BremerhavenGuideImg,
      name: "BremerhavenGuide - iOS",
      linkTo: "/BremerhavenGuideIOS",
      category: "AR Development",
    },
    {
      id: 14,
      src: PlaceholderImg,
      name: "BIBA",
      linkTo: "/BIBA",
      category: "AR Development",
    },
    {
      id: 15,
      src: BremenGoImg,
      name: "BremenGo - iOS",
      linkTo: "/BremenGoIOS",
      category: "AR Development",
    },
  ];

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    setCurrentSlide(0);
    carouselRef.current?.goTo(0);
  };

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();
    const delta = Math.sign(e.deltaY);
    if (delta > 0) {
      if (currentSlide < items.length - 1) {
        setCurrentSlide(currentSlide + 1);
        carouselRef.current?.goTo(currentSlide + 1);
      }
    } else if (delta < 0) {
      if (currentSlide > 0) {
        setCurrentSlide(currentSlide - 1);
        carouselRef.current?.goTo(currentSlide - 1);
      }
    }
  };

  const filteredItems =
    selectedTab === "All Projects"
      ? items
      : items.filter((item) => item.category === selectedTab);

  return (
    <div className="flex-grow-1 bg-black pt-0 pt-sm-5">
      <div className="row m-0 justify-content-center text-center">
        <div className="col-12 px-5 pt-4">
          <Tabs>
            <ul className="animate__animated animate__fadeInDown">
              <li
                onClick={() => handleTabClick("All Projects")}
                className={selectedTab === "All Projects" ? "active" : ""}
              >
                All Projects
              </li>
              <li
                onClick={() => handleTabClick("AR Development")}
                className={selectedTab === "AR Development" ? "active" : ""}
              >
                AR Development
              </li>
              <li
                onClick={() => handleTabClick("VR Development")}
                className={selectedTab === "VR Development" ? "active" : ""}
              >
                VR Development
              </li>
              <li
                onClick={() => handleTabClick("Web AR")}
                className={selectedTab === "Web AR" ? "active" : ""}
              >
                Web AR
              </li>
              <li
                onClick={() => handleTabClick("3D Modelling")}
                className={selectedTab === "3D Modelling" ? "active" : ""}
              >
                3D Modelling
              </li>
            </ul>
          </Tabs>
        </div>
      </div>

      {filteredItems.length > 0 ? (
        <CarouselContainer
          className="carousel-wrapper animate__animated animate__bounceIn"
          onWheel={handleWheel}
        >
          <div className="d-none d-sm-block">
            {/* @ts-ignore */}
            <Carousel
              ref={carouselRef}
              breakPoints={breakPoints}
              onSlideChange={(currentSlide: any) =>
                setCurrentSlide(currentSlide)
              }
            >
              {filteredItems.map((item, index) => (
                <SlideItem key={index}>
                  <img src={item.src} alt="" />
                  <div className="project-name">{item.name}</div>
                  <Link to={item.linkTo} className={item.className}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <PrimaryHeading className="text-gradient-secondary m-0 p-0">
                        View Details
                      </PrimaryHeading>
                    </div>
                  </Link>
                </SlideItem>
              ))}
            </Carousel>
          </div>

          <div className="d-block d-sm-none">
            {filteredItems.map((item, index) => (
              <GridBox className="p-0 position-relative">
                <img src={item.src} alt="" />
                <div className="project-name">{item.name}</div>
                <div className="viewdetails">
                  <Link to={item.linkTo} className={item.className}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <PrimaryHeading className="display-1 m-0 p-0">
                        View Details
                      </PrimaryHeading>
                    </div>
                  </Link>
                </div>
              </GridBox>
            ))}
          </div>
        </CarouselContainer>
      ) : (
        <div>No projects found for the selected category.</div>
      )}

      {/* {selectedTab === "AR Development" && (
        <div>Content for AR Development tab</div>
      )}
      {selectedTab === "VR Development" && (
        <div>Content for VR Development tab</div>
      )}
      {selectedTab === "Web AR" && <div>Content for Web AR tab</div>}
      {selectedTab === "3D Modelling" && (
        <div>Content for 3D Modelling tab</div>
      )} */}
    </div>
  );
}
