import React, { useEffect, useRef, useState, useCallback } from "react";
import { GridBox } from "../../../UIComponents/FourGrid/styles";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { PrimaryHeading, ProgressStepper, Section } from "../../../styles";
import Button from "../../../DesignSystem/Button";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
} from "./styles";

export default function HoloConnect() {
  const PR2 = require("../../../../assets/images/pr-2.jpeg");
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const R1 = require("../../../../assets/images/projects/robot-simulation/r1.png");
  const R2 = require("../../../../assets/images/projects/robot-simulation/r2.png");
  const R3 = require("../../../../assets/images/projects/robot-simulation/r3.png");
  const R4 = require("../../../../assets/images/projects/robot-simulation/r4.png");
  const R5 = require("../../../../assets/images/projects/robot-simulation/r5.png");
  const Grid1 = require("../../../../assets/images/projects/robot-simulation/grid1.svg");
  const Grid2 = require("../../../../assets/images/projects/robot-simulation/grid2.png");
  const Grid3 = require("../../../../assets/images/projects/robot-simulation/grid3.png");

  const lightGallery = useRef<any>(null);
  const [items, setItems] = useState([
    {
      id: "1",
      size: "1400-933",
      src: R1,
      thumb: R1,
      subHtml: ``,
    },

    {
      id: "2",
      size: "1400-933",
      src: R2,
      thumb: R2,
      subHtml: ``,
    },
    {
      id: "3",
      size: "1400-932",
      src: R3,
      thumb: R3,
      subHtml: ``,
    },

    {
      id: "4",
      size: "1400-932",
      src: R4,
      thumb: R4,
      subHtml: ``,
    },
    {
      id: "5",
      size: "1400-932",
      src: R5,
      thumb: R5,
      subHtml: ``,
    },
  ]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return items.map((item) => {
      return (
        <a
          key={item.id}
          data-lg-size={item.size}
          className="gallery-item"
          data-src={item.src}
        >
          <img className="img-responsive w-100 rounded" src={item.thumb} />
        </a>
      );
    });
  }, [items]);

  useEffect(() => {
    lightGallery.current.refresh();
    console.log("videl", lgVideo);
  }, [items]);

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  HoloConnect
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  HoloConnect - Enabling Remote Multi-User Collaboration with
                  Welcome to HoloConnect, an innovative prototype application
                  designed to facilitate remote multi-user collaboration using
                  the power of Hololens. With HoloConnect, users can transcend
                  physical boundaries and engage in immersive shared
                  experiences, unlocking new possibilities for teamwork and
                  communication.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid1} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Remote Multi-User Collaboration:</b>HoloConnect brings
                  people together in a shared mixed reality space, regardless of
                  their physical locations. Users wearing Hololens devices can
                  connect and collaborate in real-time, interacting with virtual
                  content and each other's avatars. This remote multi-user
                  capability promotes collaboration, knowledge sharing, and
                  seamless teamwork.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Immersive Shared Experiences: </b>With HoloConnect, users
                  can enjoy immersive shared experiences by placing virtual
                  content in their physical surroundings. Whether it's
                  visualizing 3D models, annotating objects, or conducting
                  virtual meetings, HoloConnect provides a platform for users to
                  interact and collaborate in a truly engaging and spatially
                  aware environment.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>Real-Time Communication and Interaction:</b> HoloConnect
                  enables users to communicate and interact in real-time,
                  transcending the limitations of distance. Users can voice
                  chat, share ideas, and collaborate on projects as if they were
                  physically present. This seamless communication fosters
                  efficient decision-making, problem-solving, and brainstorming
                  sessions, enhancing productivity and fostering stronger
                  connections among team members.
                </p>
                <p className="pb-2">
                  <b>Versatile Applications:</b> HoloConnect finds applications
                  across various domains, including remote training, design
                  reviews, virtual conferences, and remote assistance. From
                  conducting immersive training sessions to conducting
                  interactive design reviews, HoloConnect empowers users to
                  collaborate effectively and efficiently, bringing teams
                  together irrespective of their physical locations.
                </p>
              </div>
            </div>

            <section className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0 styled-text1 text-warning">
                        HoloConnect revolutionizes remote collaboration by
                        leveraging the capabilities of Hololens. By merging
                        virtual and physical realities, this cutting-edge
                        application opens up new avenues for multi-user
                        collaboration, empowering teams to work together, share
                        ideas, and accomplish tasks in a highly immersive and
                        interactive environment. Embrace the future of remote
                        collaboration with HoloConnect.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <GalleryContainer>
              <LightGallery
                plugins={[lgVideo, lgZoom]}
                elementClassNames="gallery-custom-wrapper row flex-column flex-sm-row"
                onInit={onInit}
                data-lg-size={2}
              >
                {getItems()}
              </LightGallery>
            </GalleryContainer>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
