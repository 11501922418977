import React, { useEffect, useRef } from "react";
import { StyledPersonIconContainer, Banner } from "./styles";
import { CanvasContainer, PrimaryHeading } from "../styles";

export default function Home() {
  const Img1 = require("../../assets/images/person2.png");

  return (
    <main className="page-wrapper flex-grow-1 bg-black">
      <Banner className="pt-0 pt-sm-4">
        <div className="container">
          <div className="pt-5 mt-5 m-0 row d-flex align-items-center">
            <div className="col-12 col-sm-7 d-flex flex-column text-md-start text-center ps-0">
              <h5 className="text-white animate__animated animate__fadeInDown">
                INTRODUCTION
              </h5>
              <PrimaryHeading className="display-1 pb-2 pb-sm-3 animate__animated animate__fadeInUp">
                I'm Bharath Chandra Reddy Vadde, and I am an experienced
                Augmented & Mixed Reality developer
              </PrimaryHeading>
              <p className="text-white  animate__animated animate__fadeInUp introduction-text">
                I'm a Senior Augmented/Mixed Reality Developer specializing in
                Unity and C#. With a passion for creating immersive experiences,
                I develop cutting-edge AR applications and enhance user
                interactions. Explore my portfolio and discover the future of
                augmented reality!
              </p>

              <div className="row m-0 experience-container">
                <div className="col-6 col-sm-4 left  animate__animated animate__fadeInLeft">
                  <h3>5 +</h3>
                  <p>Years of experience</p>
                </div>
                <div className="col-6 col-sm-4 ps-sm-5 righ  animate__animated animate__fadeInRight">
                  <h3>11 +</h3>
                  <p>Projects Completed</p>
                </div>
              </div>
              {/* <div className="d-flex justify-content-center justify-content-md-start pb-2 pt-lg-2 pt-xl-0">
              <Button isHideIcon={true}>Browse Resume</Button>
            </div> */}
            </div>
            <StyledPersonIconContainer className="col-12 col-sm-5 d-flex justify-content-center animate__animated animate__fadeInLeft">
              {/* <div className="lottie">
              <Lottie
                animationData={Lottie1}
                loop={true}
                className="lottie-icon"
              />
            </div> */}
              <img src={Img1} alt="" className="w-100" />
            </StyledPersonIconContainer>
          </div>
        </div>
      </Banner>
    </main>
  );
}
