import React, { FunctionComponent } from "react";
import { Link, useLocation } from "react-router-dom";
import { HeaderWrapper } from "./styles";
import Button from "../../DesignSystem/Button";

const Header: FunctionComponent = () => {
  const location = useLocation();
  const logoImg = require("../../../assets/images/allesxr-dark-logo.png");

  const handleDownloadCV = () => {
    // Replace the fileUrl with the actual URL of your PDF file
    const fileUrl = require("../../../assets/profile-bharathreddy.pdf");
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "profile.pdf";
    link.click();
  };

  return (
    <HeaderWrapper>
      <nav className="navbar navbar-expand-lg autohide shadow-sm py-sm-0">
        <div className="container px-3">
          <Link to="/" className="navbar-brand">
            <img src={logoImg} alt="" style={{ width: "50%" }} />
          </Link>
          <div id="navbarNav" className="offcanvas offcanvas-end vh-100">
            <div className="offcanvas-header border-bottom border-dark">
              <img src={logoImg} alt="" style={{ width: "70%" }} />
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li
                  className={`nav-item ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/Projects" ? "active" : ""
                  }`}
                >
                  <Link to="/Projects" className="nav-link">
                    My Projects
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/AboutUs" ? "active" : ""
                  }`}
                >
                  <Link to="/AboutUs" className="nav-link">
                    About me
                  </Link>
                </li>
                <li
                  className={`nav-item ${
                    location.pathname === "/Contact" ? "active" : ""
                  }`}
                >
                  <Link to="/Contact" className="nav-link">
                    Contact me
                  </Link>
                </li>
                <li className={"nav-item pt-3 ms-0 d-block d-sm-none"}>
                  <Button
                    isHideIcon={true}
                    className="d-block d-sm-none text-bg-primary"
                    onClick={handleDownloadCV}
                  >
                    {" "}
                    Download CV
                  </Button>
                </li>
              </ul>
            </div>
          </div>

          <Button
            isHideIcon={true}
            className="d-none d-sm-block animate__animated animate__bounceIn"
            onClick={handleDownloadCV}
          >
            {" "}
            Download CV
          </Button>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
      </nav>
    </HeaderWrapper>
  );
};

export default Header;
