import { Route, Switch } from "react-router-dom";
import React from "react";
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import Contact from "../Pages/Contact";
import Videos from "../Pages/Videos";
import Projects from "../Pages/Projects";

import RobotSimulation from "../Pages/Projects/Works/RobotSimulation";
import GCAssist from "../Pages/Projects/Works/GCAssist";
import HoloworkAssist from "../Pages/Projects/Works/HoloworkAssist";
import ArgoHytosAR from "../Pages/Projects/Works/ArgoHytosAR";
import RyllMesse from "../Pages/Projects/Works/RyllMesse";
import PilkingtonAR from "../Pages/Projects/Works/PilkingtonAR";
import ARConnect from "../Pages/Projects/Works/ARConnect";
import HoloConnect from "../Pages/Projects/Works/HoloConnect";
import SaackeScrubber from "../Pages/Projects/Works/Saacke-Scrubber";
import ConelWeb from "../Pages/Projects/Works/ConelWeb";
import StolpersteinePrototype from "../Pages/Projects/Works/StolpersteinePrototype";
import StolpersteineIOS from "../Pages/Projects/Works/Stolpersteine-IOS";
import BremerhavenGuideIOS from "../Pages/Projects/Works/BremerhavenGuide-IOS";
import BIBA from "../Pages/Projects/Works/BIBA";
import BremenGoIOS from "../Pages/Projects/Works/BremenGo-IOS";
import TemplateTwo from "../Pages/Projects/Works/TemplateTwo";

export function Router() {
  return (
    <Switch>
      <Route exact path={"/"} component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/AboutUs" component={AboutUs} />
      <Route path="/Contact" component={Contact} />
      <Route path="/Videos" component={Videos} />
      <Route path="/RobotSimulation" component={RobotSimulation} />{" "}
      <Route path="/GCAssist" component={GCAssist} />{" "}
      <Route path="/HoloworkAssist" component={HoloworkAssist} />{" "}
      <Route path="/ArgoHytosAR" component={ArgoHytosAR} />{" "}
      <Route path="/RyllMesse" component={RyllMesse} />{" "}
      <Route path="/PilkingtonAR" component={PilkingtonAR} />{" "}
      <Route path="/ARConnect" component={ARConnect} />{" "}
      <Route path="/HoloConnect" component={HoloConnect} />{" "}
      <Route path="/Saacke-Scrubber" component={SaackeScrubber} />{" "}
      <Route path="/ConelWeb" component={ConelWeb} />{" "}
      <Route
        path="/Stolpersteine Prototype"
        component={StolpersteinePrototype}
      />{" "}
      <Route path="/StolpersteineIOS" component={StolpersteineIOS} />{" "}
      <Route path="/BremerhavenGuideIOS" component={BremerhavenGuideIOS} />{" "}
      <Route path="/BIBA" component={BIBA} />{" "}
      <Route path="/BremenGoIOS" component={BremenGoIOS} />{" "}
      <Route path="/TemplateTwo" component={TemplateTwo} />
      <Route path="/Projects" component={Projects} />
    </Switch>
  );
}

export default Router;
