import React, { FunctionComponent, ReactNode } from "react";
import { Button as CustomButton } from "./styles";
import { variant as variantType } from "./buttonVariants";
import _ from "lodash";

export interface ButtonProps {
  children: ReactNode;
  loading?: boolean;
  variant?: variantType;
  disabled?: boolean;
  onClick?: (...args: unknown[]) => unknown;
  title?: string;
  className?: string;
  onKeyPress?: any;
  id?: string;
  type?: any;
  value?: any;
  style?: any;
  tooltip?: any;
  tabIndex?: number;
  isHideIcon?: boolean;
}

const Button: FunctionComponent<ButtonProps> = (props: ButtonProps) => {
  return (
    <CustomButton
      as="button"
      className={props.className}
      type={props.type}
      onClick={props.onClick}
      // tabIndex={props.tabIndex}
      // disabled={props.disabled || false}
      // title={props.title}
      // style={style}
    >
      {props.children}
      {!props.isHideIcon && <i className="fa fa-chevron-right ms-2" />}
    </CustomButton>
  );
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  title: "",
  variant: "primary",
  className: "shadow-primary px-4",
  type: "button",
};

export default Button;
