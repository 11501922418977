import React from "react";
import Header from "./UIComponents/Header";
import Footer from "./UIComponents/Footer";
import Router from "./routes";
import { MainContainer } from "./styles";

function AppContainer() {
  return (
    <MainContainer className={"main h-100 d-flex flex-column"}>
      <Header />
      <Router />
      <Footer />
    </MainContainer>
  );
}

export default AppContainer;
