import styled from "styled-components";

export const ContactContainer = styled.div`
  .address-text,
  a,
  i {
    font-size: 18px;
    font-weight: 600;
    color: #0d6efd;
    text-decoration: none;
  }

  label {
    color: white;
  }

  .form-control {
    background-color: #161111;
    border: none;
    color: white;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgb(255 255 255 / 50%);
  }

  .card {
    padding: 20px 40px;
    margin: 0 180px;

    @media (max-width: 480px) {
      padding: 30px;
      margin: 0;
    }
  }

  .grids {
    padding-top: 60px;
    padding-bottom: 30px;

    h4 {
      margin-bottom: 8px !important;
      font-size: 18px;
    }

    p,
    h4 {
      color: white;
      margin: 0;
    }

    p {
      font-size: 14px;
    }

    .vh-centered {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .icon-container {
      background-color: rgb(138 138 138 / 18%);
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 12px;
    }
  }
`;
