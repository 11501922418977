import styled from "styled-components";

export const CarouselContainer = styled.div`
  padding: 20px;

  .rec-carousel button,
  .rec-pagination {
    display: none !important;
  }

  .rec-item-wrapper {
    width: 100% !important;
  }

  .rec-carousel-item {
    border: solid 10px transparent;
    border-radius: 12px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }
  }

  .project-name {
    position: absolute;
    top: 30%;
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: white;
  }
`;

export const SlideItem = styled.div`
  display: flex;
  height: 60vh;
  width: 100%;
  position: relative;

  img {
    border-radius: 12px;
    opacity: 0.8;
    width: 302px;
    object-fit: cover;
  }

  a {
    color: white;
    font-size: 14px;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    background-color: rgb(53 53 53 / 92%);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 10px 0;
    text-decoration: none;
  }

  .disabled {
    cursor: no-drop;
    background: #ccc;
    opacity: 0.5;
  }

  p {
    margin-bottom: 0;
  }
`;

export const Tabs = styled.div`
  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0;

    li {
      margin: 0 16px;
      background-color: white;
      border-radius: 24px;
      padding: 5px 24px;
      color: #605d5d;
      cursor: pointer;

      &:hover {
        background-color: #007eee;
        color: white;
      }

      @media (max-width: 480px) {
        margin: 10px 5px;
      }
    }

    .active {
      background-color: #007eee;
      color: white;
    }
  }
`;

export const ProjectGrid = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  flex-direction: column;
  display: flex;
  margin-bottom: 26px;

  img {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  p {
    font-weight: 600;
  }

  &:hover {
    transform: scale(1.02);
  }

  .disabled {
    cursor: no-drop;
    opacity: 0.7;
  }
`;

export const GalleryContainer = styled.div`
  .gallery-custom-wrapper {
    display: flex;
    flex-wrap: wrap;

    a {
      flex: 1;
      margin: 16px 0;
      /* border: solid 1px #eee;
      border-radius: 12px; */
      margin: 10px;
    }
  }
`;

export const ImageRotation = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;
