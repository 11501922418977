import styled from "styled-components";

export const ProjectPeriodSection = styled.div`
  .grid {
    border-bottom: solid 1px #eee;
  }

  b {
    color: white !important;
  }
`;

export const StyledImageGrid = styled.img`
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #5b78f624 -15%,
    #c2ebff 58%,
    #5b78f67d 97%
  ) !important;
  padding: 4px;
`;

export const DetailsPageStyles = styled.div`
  p,
  b {
    color: white;
    margin-bottom: 6px;
  }

  section {
    b {
      color: #adbfff;
    }
  }
`;

export const ConcludeContainer = styled.section`
  background-color: #131111;
  border-radius: 12px;

  p {
    color: white;
  }
`;
