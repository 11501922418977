import React from "react";
import { PrimaryHeading, Section } from "../styles";
import Lottie from "lottie-react";
import { GridBox } from "../UIComponents/FourGrid/styles";
import { ContactContainer } from "./styles";
import Button from "../DesignSystem/Button";

const Contact = () => {
  const Map = require("../../assets/images/allesxr-map.svg");
  const Lottie1 = require("../../assets/lottie/location-map.json");
  const Lottie2 = require("../../assets/lottie/mail-read.json");
  const Lottie3 = require("../../assets/lottie/contacts.json");

  return (
    <ContactContainer className="bg-black p-3 p-sm-5 pt-5 h-100">
      <div>
        <div className="container">
          <div className="m-0 row d-flex justify-content-center align-items-center animate__animated animate__fadeInUp">
            <div className="col-12 d-flex justify-content-center">
              <PrimaryHeading className="display-3 pb-2 pb-sm-3">
                Connect with me!
              </PrimaryHeading>
            </div>
            <div className="col-12">
              <p className="text-white text-center px-0 px-sm-0 mx-0 mx-sm-5 pb-4">
                With a proven track record of delivering high-quality AR
                applications that delight users, I'm excited to continue pushing
                the boundaries of augmented reality, creating immersive and
                memorable experiences that captivate audiences and enhance user
                engagement.
              </p>
            </div>
          </div>

          <div className="bg3 card animate__animated animate__fadeInUp rounded">
            <form>
              <input
                type="hidden"
                name="_token"
                defaultValue="qbb1gapMo8EZg9Dc1qKCNarwH28RoXajgNx0xmz9"
              />{" "}
              <div className="row">
                <div className="col-md-6 mb-3 pb-1">
                  <label className="form-label">
                    First name<sup className="text-danger ms-1">*</sup>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="first_name"
                    id="first_name"
                    placeholder="Enter First Name"
                  />
                  <div className="invalid-feedback">
                    Please enter your first name!
                  </div>
                  <div className="valid-feedback">You're awesome!</div>
                </div>
                <div className="col-md-6 mb-3 pb-1">
                  <label className="form-label">
                    Last name
                    <sup className="text-danger ms-1" />
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Enter Last Name"
                  />
                  <div className="invalid-feedback">
                    Please enter your last name!
                  </div>
                  <div className="valid-feedback"> </div>
                </div>
              </div>
              <div className="mb-3 pb-1">
                <label className="form-label">
                  Email address<sup className="text-danger ms-1">*</sup>
                </label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="name@example.com"
                />
                <div className="invalid-feedback">
                  Please enter a valid email address!
                </div>
                <div className="valid-feedback">You're awesome!</div>
              </div>
              <div className="mb-3 pb-1">
                <label className="form-label">
                  Message<sup className="text-danger ms-1">*</sup>
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  id="cont-message"
                  placeholder="Write a message"
                  defaultValue={""}
                />
                <div className="invalid-feedback">Please write a message!</div>
                <div className="valid-feedback">You're awesome!</div>
              </div>
              <div className="text-center pt-2">
                <Button
                  className="btn btn-outline-dark"
                  type="submit"
                  isHideIcon={true}
                >
                  <span className="">Send Message</span>
                </Button>
              </div>
            </form>
          </div>

          <div className="d-flex justify-content-around grids flex-wrap">
            <div className="vh-centered pb-5 pb-sm-0 animate__animated animate__fadeInLeft">
              <div className="icon-container">
                {" "}
                <Lottie
                  animationData={Lottie1}
                  loop={true}
                  style={{ height: 300 }}
                />
              </div>
              <h4>Visit My Place</h4>
              <p>Hochschule Ravensburg-Weingarten </p>
              <p>Mon - Fri (10AM - 7PM)</p>
            </div>
            <div className="vh-centered pb-5 pb-sm-0 animate__animated animate__fadeInUp">
              <div className="icon-container">
                {" "}
                <Lottie
                  animationData={Lottie2}
                  loop={true}
                  style={{ height: 300 }}
                />
              </div>
              <h4>Email Me!</h4>
              <p>bharath09vadde@gmail.com </p>
              <p>Weingarten, Baden Württemberg</p>
            </div>{" "}
            <div className="vh-centered animate__animated animate__fadeInRight">
              <div className="icon-container">
                {" "}
                <Lottie
                  animationData={Lottie3}
                  loop={true}
                  style={{ height: 300 }}
                />
              </div>
              <h4>Connect With Me!</h4>
              <p>Mobile: +1 764-716-1890</p>
              <p>Fax: +1 764-716-1890</p>
            </div>
          </div>
        </div>
      </div>
    </ContactContainer>
  );
};

export default Contact;
