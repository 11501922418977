import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function StolpersteinePrototype() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/stolperstein-nrw/t1.png");
  const T2 = require("../../../../assets/videos/stolperstein-nrw/t2.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Honoring Holocaust Victims through Augmented Reality
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Welcome to the innovative digital service "Stolpersteine NRW”
                  - New digital WDR offer against forgetting, a project
                  dedicated to honoring the memory of Holocaust victims in North
                  Rhine-Westphalia (NRW). This project combines cutting-edge
                  technology, extensive research, and a commitment to
                  remembrance. The app "Stolpersteine NRW, Against Forgetting"
                  has won the "Grimme Online Award 2023" in the category
                  "Knowledge and Education".
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/stolperstein-nrw/1_Stolperstein.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Interactive Stumbling Blocks </b>
                </p>
                <p>
                  The heart of the project lies in the approximately 15,000
                  stumbling blocks spread across NRW. With our mobile app, users
                  can navigate the streets and find these memorial stones, each
                  representing an individual victim of the Holocaust. By simply
                  standing in front of a stumbling block, users can access
                  detailed information about the person memorialized, uncovering
                  their story and preserving their memory.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b> Augmented Reality Experiences </b>
                </p>
                <p>
                  I had the privilege of contributing to this project by
                  prototyping the app and exploring various Augmented Reality
                  (AR) technologies. Although my work with Azure spatial anchors
                  did not make it into the final version, I focused on creating
                  compelling AR experiences. Users can engage with historical
                  pictures, explore biographies, listen to audio stories, and
                  even place virtual candles to commemorate the victims. This AR
                  component adds a poignant and immersive layer to the
                  remembrance experience.
                </p>
                <p className="pb-2 mt-4">
                  <b>Impactful Engagement</b>
                </p>
                <p>
                  {" "}
                  Since its release, the Stolpersteine NRW app has garnered more
                  than 35,000 downloads across the Play Store and App Store. The
                  app's popularity is a testament to the significance of
                  honoring the victims and ensuring their stories are not
                  forgotten. Through the power of technology and user-friendly
                  interfaces, The app strive to engage and educate a wide
                  audience, fostering empathy, and promoting remembrance.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/stolperstein-nrw/2_Stolperstein.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        In conclusion, Stolpersteine NRW is an innovative
                        digital service that combines technology, research, and
                        dedication to remember the victims of the Holocaust.
                        Through our app and website, users can interact with
                        stumbling blocks, access detailed biographies, and
                        engage in immersive Augmented Reality experiences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
