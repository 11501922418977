import { css } from "styled-components";

export const CustomStyles = css`
  .text-gradient-primary {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#146ad3),
      color-stop(#8b5cf6),
      to(#d946ef)
    );
    background: linear-gradient(to right, #146ad3, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-gradient-secondary {
    background: radial-gradient(
        circle closest-corner at 42% -100%,
        #fa4b4b,
        rgba(237, 155, 169, 0)
      ),
      linear-gradient(103deg, #949494 16%, #ffa61d 88%);
    background: linear-gradient(to right, #146ad3, #8b5cf6, #d946ef);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 24px;
  }

  .heading-hero {
    color: #fafaf6;
    cursor: default;
    font-family: antique-olive, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
    font-weight: 500;
    font-style: normal;
    line-height: 0.95;
  }

  .cta-colorful-text1 {
    background-image: radial-gradient(
        circle closest-corner at 42% -100%,
        #ed9ba9,
        rgba(237, 155, 169, 0)
      ),
      linear-gradient(103deg, #9076fc 16%, #61cfeb 88%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .styled-text1 {
    color: #0069ff;
  }

  .text-dark {
    color: #121519 !important;
  }

  .lead {
    font-size: 1.012rem;
    font-weight: 400;
  }
  .fs-sm {
    font-size: 0.805rem !important;
  }

  .shadow-dark {
    box-shadow: rgba(18, 21, 25, 0.9) 0px 0.5rem 1.125rem -0.5rem !important;
  }

  .bg1 {
    background: #f4f9fc;
  }

  .bg2 {
    background: #262020;
  }

  .bg3 {
    background: #252222;
  }

  .bg-black {
    background: #000;
  }

  .bg-white {
    background: #fff;
  }

  .nav-tabs-alt > .nav-item > .dropdown-menu {
    margin-top: -0.125rem !important;
    border-top-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .nav-item {
    margin-bottom: 0;
    padding: 0 12px;
  }

  .nav {
    --si-nav-link-active-color: #146ad3;
  }

  .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
  }

  .nav-link.active {
    color: var(--si-nav-link-active-color);
  }

  .nav-link.active:not([data-bs-toggle="dropdown"]) {
    pointer-events: none;
    cursor: default;
  }

  .nav-tabs {
    --si-nav-tabs-border-width: 0;
    --si-nav-tabs-border-color: transparent;
    --si-nav-tabs-border-radius: 0.375rem;
    --si-nav-tabs-link-hover-border-color: transparent;
    --si-nav-tabs-link-active-color: #ffffff;
    --si-nav-tabs-link-active-bg: #146ad3;
    --si-nav-tabs-link-active-border-color: transparent;
    border-bottom: var(--si-nav-tabs-border-width) solid
      var(--si-nav-tabs-border-color);
  }

  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--si-nav-tabs-border-width));
    background: none;
    border: var(--si-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--si-nav-tabs-border-radius);
    border-top-right-radius: var(--si-nav-tabs-border-radius);
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: var(--si-nav-tabs-link-hover-border-color);
  }

  .nav-tabs .nav-link.disabled,
  .nav-tabs .nav-link:disabled {
    color: var(--si-nav-link-disabled-color);
    background-color: transparent;
    border-color: transparent;
  }

  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: var(--si-nav-tabs-link-active-color);
    background-color: var(--si-nav-tabs-link-active-bg);
    border-color: var(--si-nav-tabs-link-active-border-color);
  }

  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--si-nav-tabs-border-width));
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .rounded-pill {
    border-radius: var(--si-border-radius-pill) !important;
  }

  .rounded {
    border-radius: 20px !important;
  }

  .highlight-gradiant {
    background: linear-gradient(90deg, #3c58e3 -15%, #273159 58%, #9a9a9a 97%);
    border-radius: 30px;
  }

  .highlight-gradiant1 {
    background: linear-gradient(90deg, #1d1e20 -15%, #1c1d1f 58%, #252525 97%);
    border-radius: 30px;
  }
`;
