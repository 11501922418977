// import styled from "styled-components";

export const typography = {
  font: {
    family: "Arial, sans-serif",
    size: {
      f14: "14",
      f16: "16",
      f18: "18",
      f20: "20",
      f24: "24",
      f26: "26",
      f36: "36",
    },
    weight: {
      normal: "400",
      bold: "700",
    },
  },
};
