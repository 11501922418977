import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function HoloworkAssist() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/holowork-assist/t1.png");
  const T2 = require("../../../../assets/videos/holowork-assist/t2.png");
  const T3 = require("../../../../assets/videos/holowork-assist/t3.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  HOLOWORK -Mobile, Hololens and WebGl
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  HOLOWORK ASSIST is a digital assistance solution for assembly,
                  maintenance and repair. It offers mixed reality, interactive
                  3D and augmented reality to support activities in the
                  technical environment that require explanation. With assist
                  functions for assembly, operation and service. Simple and
                  self-explanatory. Functions for ordering spare parts and an
                  optional video and document library.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/holowork-assist/1_Holowork.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Mixed Reality (Hololens)</b>
                </p>
                <p>
                  Experience unparalleled job support with our Mixed Reality
                  application. Simply put on the comfortable data glasses, load
                  the digital twin, select your work routine, and you're ready
                  to go. The virtual machine appears vividly in the room, true
                  to its original form. Every work step is clearly visible and
                  easily understood. Voice control allows for menu navigation,
                  freeing your hands to interact naturally. With the tool in
                  hand, you'll enjoy faster, safer, and more efficient work.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Mobile App (Android and iOS)</b>
                </p>
                <p>
                  Boost your efficiency with Interactive 3D, conveniently
                  available on your smartphone or tablet (iOS/Android). Access
                  manuals, service routines, repair instructions, and more in a
                  three-dimensional format that is easy to use. Enjoy
                  interactive animations and explanations, ensuring you have all
                  the information you need at your fingertips. With offline
                  capabilities and real-time updates, you can have peace of mind
                  knowing you've done everything right.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/holowork-assist/2_video.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T3}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/holowork-assist/3_video.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <p className="pb-2">
                  <b>AR Mobile</b>
                </p>
                <p>
                  Ensure a seamless fit and optimal workflow with AR Mobile.
                  Wondering if a device fits in a specific space? Need to know
                  the correct order for disassembling and reassembling parts,
                  along with the required torque? AR Mobile provides clear
                  information by connecting virtual models to physical objects
                  or spaces. This feature offers increased security,
                  orientation, and fewer mistakes, ultimately resulting in
                  higher quality outcomes.
                </p>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        Embrace the power of our multi-platform applications to
                        enhance work support, streamline processes, and deliver
                        exceptional experiences. Whether you prefer the
                        immersive environment of Mixed Reality, the convenience
                        of Mobile Apps, or the accessibility of WebGL on the
                        web, our solutions are designed to empower you and your
                        team every step of the way.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
