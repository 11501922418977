import React from "react";

import { PrimaryHeading } from "../../../styles";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { GalleryContainer, ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  StyledImageGrid,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function SaackeScrubber() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");

  const T1 = require("../../../../assets/videos/saacke-scrubber/t1.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  Unleashing the Power of Exhaust Gas Cleaning Systems
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Welcome to Saacke Scrubber, a desktop application developed
                  for Saacke GmbH. Explore the simulated operations of the
                  Saacke exhaust gas cleaning system in a captivating and
                  interactive environment.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/saacke-scrubber/1_SaackeScrubber.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Two Modes </b>
                </p>
                <p>
                  {" "}
                  Discover the functionalities of the Saacke scrubber in both
                  Open Loop and Closed Loop modes, and witness their impact on a
                  big ship operating in international waters.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-12 ">
                <p className="pb-2">
                  <b>Realistic Simulation</b>
                </p>
                <p>
                  Experience the intricate mechanisms and processes of the
                  exhaust gas cleaning system through realistic simulation and
                  visual representation.
                </p>
              </div>
              {/* <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid2} alt="" className="w-100" />
                </div>
              </div> */}
            </div>

            <div className="m-0 row d-flex align-items-center mb-5">
              {/* <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <StyledImageGrid src={Grid3} alt="" className="w-100" />
                </div>
              </div> */}
              <div className="col-12 col-sm-12">
                <p className="pb-2">
                  <b>Comprehensive Learning</b>
                </p>
                <p>
                  Gain in-depth knowledge about the working principles,
                  technical aspects, and advantages of Saacke scrubbers through
                  intuitive visuals, texts, and interactive elements.
                </p>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        Saacke Scrubber offers a unique opportunity to
                        understand the innovative exhaust gas cleaning
                        technology employed by Saacke GmbH. Dive into the world
                        of maritime operations and witness the power of
                        sustainable marine solutions. Please note that Saacke
                        Scrubber is a desktop application developed exclusively
                        for Saacke GmbH and is not available for public
                        download.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
