import React from "react";
import { PrimaryHeading } from "../../../styles";
import { ImageRotation } from "../styles";
import {
  ProjectPeriodSection,
  DetailsPageStyles,
  ConcludeContainer,
} from "./styles";

export default function ConelWeb() {
  const ProjectBG = require("../../../../assets/images/projects/robot-simulation/bg.svg");
  const T1 = require("../../../../assets/videos/conel-web/t1.png");
  const T2 = require("../../../../assets/videos/conel-web/t2.png");

  return (
    <DetailsPageStyles className="d-flex flex-grow-1 bg-black overflow-auto">
      <ImageRotation className="col-3 d-none d-sm-block animate__animated animate__backInLeft">
        <img src={ProjectBG} alt="" />
      </ImageRotation>
      <div className="col-12 col-sm-9 overflow-auto">
        <section>
          <div className="container">
            <div className="row justify-content-center text-center py-5 animate__animated animate__fadeInUp">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-3">
                  ConelWeb - 3D Modeling and Web Development
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-9">
                <p className="mb-0">
                  Welcome to Conel’s web project that combines the power of 3D
                  modeling and web development to create an immersive and
                  engaging product showcase. This project specifically focuses
                  on utilizing Blender and Three.js to prepare 3D models of
                  Conel's various products and interaction in the web
                  environment.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="m-0 row d-flex align-items-center mb-5">
              <div className="col-12 col-sm-6 animate__animated animate__fadeInLeft">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T1}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/conel-web/1_conel+web.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-12 col-sm-6 animate__animated animate__fadeInRight">
                <p className="pb-2">
                  <b>Enhancing User Experience</b>
                </p>
                <p>
                  {" "}
                  By leveraging the capabilities of 3D modeling, I have
                  transformed Conel's products into interactive and visually
                  captivating models. Users can now explore the dimensions and
                  intricate details of the products in a dynamic and intuitive
                  manner, offering a truly immersive experience.
                </p>
                <p className="pb-2 pt-4">
                  <b> Seamless Web Integration</b>
                </p>
                <p>
                  Through the utilization of Three.js, I have enabled users to
                  access and interact with the models directly from their web
                  browsers, eliminating the need for specialized software or
                  plugins.
                </p>
              </div>
            </div>

            <div className="m-0 row d-flex align-items-center mb-5 animate__animated animate__fadeInUp">
              <div className="col-12 col-sm-6 ps-2 ps-sm-5 order-1 order-sm-0 ">
                <p className="pb-2">
                  <b>Exploring Dimensions and Information Points </b>
                </p>
                <p>
                  {" "}
                  Our web solution goes beyond basic 3D visualization by
                  incorporating interactive elements. Users can delve into the
                  dimensions of the 3D models, gaining a comprehensive
                  understanding of the product's size and proportions.
                  Additionally, information points placed strategically on the
                  model provide users with detailed insights, such as product
                  specifications or specific features.
                </p>
                <p className="pb-2 pt-4">
                  <b> Engaging and Unique Showcase</b>
                </p>
                <p>
                  {" "}
                  This project serves as a prime example of how 3D modeling and
                  web development can transform the way products are showcased.
                  By offering an engaging and unique interactive experience, I
                  aim to captivate users and provide them with an in-depth
                  understanding of Conel's products, ultimately influencing
                  their purchasing decisions.
                </p>
              </div>
              <div className="col-12 col-sm-6">
                <div className="p-0 p-sm-5 pb-4">
                  <video width="100%" height="height" controls poster={T2}>
                    <source
                      src={
                        "https://bharathvadde.s3.ap-south-1.amazonaws.com/conel-web/2_conel+web.mp4"
                      }
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

            <ConcludeContainer className="py-5">
              <div className="container">
                <div className="row">
                  <div className="row justify-content-center text-center pt-4 pb-sm-2 pt-lg-3 mb-3 ">
                    <div className="col-12 col-sm-9">
                      <p className="mb-0">
                        In summary, our project demonstrates the power of 3D
                        modeling and web development in enhancing the user
                        experience and providing a captivating showcase for
                        Conel's products. Through a seamless web integration,
                        users can explore the dimensions, interact with
                        information points, and gain a comprehensive
                        understanding of the products. Embrace the future of
                        product showcasing with our innovative web solution.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </ConcludeContainer>

            <ProjectPeriodSection className="m-0 row d-flex align-items-start mb-5">
              <div className="col-12 d-flex justify-content-center">
                <PrimaryHeading className="display-3 py-5">
                  Project Details
                </PrimaryHeading>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Period</b>
                  </h3>
                  <p>October - December 2021</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Client</b>
                  </h3>
                  <p>Holicm Pvt, Ltd.</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Cooperation</b>
                  </h3>
                  <p>Victor Stockert</p>
                </div>
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Links</b>
                  </h3>
                  <p>holicm.com</p>
                </div>
              </div>
              <div className="col-12 col-sm-4 ps-2 ps-sm-5">
                <div className="grid">
                  <h3 className="mb-0">
                    <b>Team</b>
                  </h3>
                  <p>
                    Christian, Fretig, Bharath Reddy, Kumar, Sankar, Poul Villa
                    and Tyler
                  </p>
                </div>
              </div>
            </ProjectPeriodSection>
          </div>
        </section>
      </div>
    </DetailsPageStyles>
  );
}
